interface Props extends React.HTMLAttributes<HTMLDivElement> {
  invalid: boolean
  position: 'top' | 'bottom'
}

export const RangeControl: React.FC<Props> = ({
  invalid,
  position,
  ...props
}) => {
  return (
    <div
      {...props}
      className={`absolute h-8 w-8 ${invalid ? 'bg-red-900' : 'bg-white'} rounded-bl-full rounded-tl-full rounded-tr-full ${
        position === 'top'
          ? `-left-4 -top-10 rotate-[45deg]`
          : `-bottom-10 -right-4 rotate-[-135deg]`
      } `}
    ></div>
  )
}
