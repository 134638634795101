import { Range } from 'common/types'
import { useEffect, useRef, useState } from 'react'
import { MAX_VOL, MIN_VOL } from 'shared/utils/web/audio'
import Waveform from './WaveForm'
import { Button } from './components'
import { useAudioPlayer } from './hooks/useAudioPlayer'

const IsLoopingStorageKey = 'isLoopingStorageKey'

type Props = {
  children?: (
    audioElementRef: React.RefObject<HTMLAudioElement>,
    audioDuration: number,
  ) => React.ReactNode
  src: string
  labelTime: string | null
  autoplay: boolean
  mutedRanges: Range[]
  playbackRates: number[]
}

export const Player = ({
  children,
  src,
  labelTime,
  autoplay,
  mutedRanges,
  playbackRates,
}: Props) => {
  const [playbackRate, setPlaybackRate] = useState(1)
  const [isLooping, setIsLooping] = useState<boolean>(
    JSON.parse(localStorage.getItem(IsLoopingStorageKey) ?? 'false'),
  )
  const [showSpectrogram, setShowSpectrogram] = useState<boolean>(false)
  const [audioDuration, setAudioDuration] = useState<number>(0)
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const audioElementRef = useRef<HTMLAudioElement>(null)

  const {
    isLoaded,
    isPlaying,
    progression: percent,
    volume,
    admissibleVolume,
  } = useAudioPlayer({
    src,
    autoplay,
    playbackRate,
    canvasRef,
    audioElementRef,
    audioDuration,
  })

  function togglePlay() {
    if (audioElementRef.current === null) return
    const audioElement = audioElementRef.current

    if (audioElement.paused) {
      audioElement.play()
    } else {
      audioElement.pause()
    }
  }

  function toggleLooping() {
    const newIsLoopingValue = !isLooping
    setIsLooping(newIsLoopingValue)
    localStorage.setItem(IsLoopingStorageKey, JSON.stringify(newIsLoopingValue))
  }

  useEffect(() => {
    if (audioElementRef.current === null) return
    const audioElement = audioElementRef.current
    audioElement.loop = isLooping
  }, [isLooping])

  useEffect(() => {
    if (audioElementRef.current === null) return
    const audioElement = audioElementRef.current

    const handleKeyUp = (e: KeyboardEvent) => {
      // Play / Pause
      if (e.key === ' ') {
        e.preventDefault()
        togglePlay()
      }
      // Move audio cursor
      if (e.key === 'ArrowLeft') {
        audioElement.currentTime = Math.min(
          audioDuration,
          Math.max(0, audioElement.currentTime - 2.0),
        )
      }
      if (e.key === 'ArrowRight') {
        audioElement.currentTime = Math.min(
          audioDuration,
          Math.max(0, audioElement.currentTime + 2.0),
        )
      }
      if (e.key === 'd' || e.key === 'D' || e.key === '0') {
        audioElement.currentTime = 0.0
        audioElement.play()
      }
    }

    const handleKeyPress = (e: KeyboardEvent) => {
      // Adjust volume
      if (e.key === '+') {
        audioElement.volume = Math.min(
          1,
          Math.max(0, audioElement.volume + 0.05),
        )
      }
      if (e.key === '-') {
        audioElement.volume = Math.min(
          1,
          Math.max(0, audioElement.volume - 0.05),
        )
      }
    }

    // set the audioDuration reliably to avoid duration gap on chrome.
    const handleLoadedMetadata = () => {
      setAudioDuration(audioElement.duration)
    }

    window.addEventListener('keyup', handleKeyUp)
    window.addEventListener('keypress', handleKeyPress)
    audioElement.addEventListener('loadedmetadata', handleLoadedMetadata)
    return () => {
      window.removeEventListener('keyup', handleKeyUp)
      window.removeEventListener('keypress', handleKeyPress)
      audioElement.removeEventListener('loadedmetadata', handleLoadedMetadata)
    }
  })

  useEffect(() => {
    const handleTimeUpdate = () => {
      if (audioElementRef.current === null) return
      const audioElement = audioElementRef.current
      let currentRange = null
      for (const range of mutedRanges) {
        if (
          audioElement.currentTime >= range.start - 0.05 &&
          audioElement.currentTime < range.end
        ) {
          currentRange = range
        }
      }
      if (currentRange) {
        audioElement.muted = true
      } else {
        audioElement.muted = false
      }
    }

    handleTimeUpdate()
  }, [percent, audioElementRef, mutedRanges])

  function handlePlaybackRateClick() {
    setPlaybackRate((prevPlaybackRate) => {
      return playbackRates[
        (playbackRates.indexOf(prevPlaybackRate) + 1) % playbackRates.length
      ]
    })
  }

  function adjustVolume(volume: number) {
    if (audioElementRef.current === null) return
    audioElementRef.current.volume = volume
  }

  return (
    <div className="flex flex-col gap-6 p-4 pt-8">
      <div className="flex flex-row items-center gap-4">
        <Button
          className="flex h-20 w-20 flex-none items-center justify-center rounded-full text-2xl"
          onMouseUp={togglePlay}
        >
          {isLoaded ? (isPlaying ? '❚❚' : '▶') : '◌'}
        </Button>
        <div className="relative flex w-full flex-col">
          <div className="relative flex h-40 flex-grow">
            <canvas className="w-full" ref={canvasRef}></canvas>
            {children ? children(audioElementRef, audioDuration) : null}
          </div>
          {showSpectrogram && (
            <Waveform
              audioElementRef={audioElementRef}
              audioDuration={audioDuration}
            />
          )}
          <div
            className="absolute inset-y-0 z-10 -ml-0.5 w-1 bg-white"
            style={{ left: `${percent}%` }}
          />
        </div>
      </div>
      <div className="flex flex-row items-center gap-4">
        {labelTime && <span>{labelTime}</span>}
        <span role="img" aria-label="Volume">
          {volume > 0.66 ? '🔊' : volume > 0.33 ? '🔉' : '🔈'}
        </span>
        <input
          className={`flex-1 ${
            volume - admissibleVolume > 0.2
              ? 'accent-red-600'
              : volume - admissibleVolume > 0
                ? 'accent-yellow-300'
                : 'accent-sky-500'
          }`}
          type="range"
          value={volume}
          min={MIN_VOL}
          max={MAX_VOL}
          step={0.01}
          onChange={(e) => adjustVolume(parseFloat(e.target.value))}
          title="Réglage du volume"
        />
        <div
          className="flex px-8 text-center"
          onClick={handlePlaybackRateClick}
        >
          {playbackRate}x
        </div>
        <Button
          className="rounded-full py-3"
          title="Activer / désactiver la lecture en boucle"
          onClick={toggleLooping}
          primary={isLooping}
        >
          <span>🔁</span>
        </Button>
        <Button
          className="rounded-full py-3"
          title="Afficher / Masquer le spectrogramme"
          onClick={() => setShowSpectrogram(!showSpectrogram)}
          primary={showSpectrogram}
        >
          <span>📊</span>
        </Button>
      </div>
      <audio src={src} ref={audioElementRef}></audio>
    </div>
  )
}
