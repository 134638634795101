import { MouseEvent, useCallback, useRef } from 'react'

type OnDragCallback = (dragData: {
  start: number
  end: number
  delta: number
}) => void
type OnEndCallback = (endData: { start: number; end: number }) => void
type OnClickCallback = (x: number) => void

const isStillClickEvent = (
  startX: number,
  endX: number,
  startTimestamp: number,
  endTimestamp: number,
) => {
  return Math.abs(endX - startX) < 10 && endTimestamp - startTimestamp < 300
}

export const useHorizontalDrag = (
  containerRef: React.RefObject<HTMLElement>,
  config: {
    onDrag?: OnDragCallback
    onEnd?: OnEndCallback
    onClick?: OnClickCallback
  },
) => {
  const { onDrag, onEnd, onClick } = config

  const onDragRef = useRef<OnDragCallback>()
  const onEndRef = useRef<OnEndCallback>()
  const onClickRef = useRef<OnClickCallback>()

  onDragRef.current = onDrag
  onEndRef.current = onEnd
  onClickRef.current = onClick

  const onMouseDown = useCallback(
    (mouseDownEvent: MouseEvent<HTMLElement>) => {
      if (containerRef.current === null) return
      const rect = containerRef.current.getBoundingClientRect()

      mouseDownEvent.preventDefault()
      mouseDownEvent.stopPropagation()

      const start = mouseDownEvent.clientX - rect.left
      const mouseDownTimestamp = mouseDownEvent.timeStamp

      let isClick = true

      let last = start

      const onMouseMove = (mouseMoveEvent: globalThis.MouseEvent) => {
        const end = mouseMoveEvent.clientX - rect.left

        if (
          isClick === false ||
          !isStillClickEvent(
            start,
            end,
            mouseDownTimestamp,
            mouseMoveEvent.timeStamp,
          )
        ) {
          isClick = false

          const delta = end - last
          last = end

          if (onDragRef.current) {
            onDragRef.current({ start, end, delta })
          }
        }
      }

      const onMouseUp = (mouseUpEvent: globalThis.MouseEvent) => {
        window.removeEventListener('mousemove', onMouseMove)
        window.removeEventListener('mouseup', onMouseUp)

        const end = mouseUpEvent.clientX - rect.left

        if (
          isClick === false ||
          !isStillClickEvent(
            start,
            end,
            mouseDownTimestamp,
            mouseUpEvent.timeStamp,
          )
        ) {
          if (onEndRef.current) onEndRef.current({ start, end })
        } else {
          if (onClickRef.current) onClickRef.current(end)
        }
      }

      window.addEventListener('mousemove', onMouseMove)
      window.addEventListener('mouseup', onMouseUp)
    },
    [containerRef],
  )

  const onTouchStart = useCallback(
    (touchStartEvent: React.TouchEvent<HTMLElement>) => {
      touchStartEvent.preventDefault()
      touchStartEvent.stopPropagation()

      if (touchStartEvent.touches.length !== 1) return

      if (containerRef.current === null) return
      const rect = containerRef.current.getBoundingClientRect()

      const start = touchStartEvent.touches.item(0).clientX - rect.left
      const touchStartTimestamp = touchStartEvent.timeStamp

      let isClick = true

      let last = start

      const onTouchMove = (touchMoveEvent: globalThis.TouchEvent) => {
        if (touchMoveEvent.touches.length === 1 && onDragRef.current) {
          const endTouch = touchMoveEvent.changedTouches.item(0)
          if (endTouch === null) return
          const end = endTouch.clientX - rect.left

          if (
            isClick === false ||
            !isStillClickEvent(
              start,
              end,
              touchStartTimestamp,
              touchMoveEvent.timeStamp,
            )
          ) {
            isClick = false

            const delta = end - last
            last = end
            if (onDragRef.current) {
              onDragRef.current({ start, end, delta })
            }
          }
        }
      }

      const onTouchEnd = (touchEndEvent: globalThis.TouchEvent) => {
        window.removeEventListener('touchmove', onTouchMove)
        window.removeEventListener('touchend', onTouchEnd)

        if (touchEndEvent.changedTouches.length === 1) {
          const endTouch = touchEndEvent.changedTouches.item(0)
          if (endTouch === null) return
          const end = endTouch.clientX - rect.left

          if (
            isClick === false ||
            !isStillClickEvent(
              start,
              end,
              touchStartTimestamp,
              touchEndEvent.timeStamp,
            )
          ) {
            if (onEndRef.current) onEndRef.current({ start, end })
          } else {
            if (onClickRef.current) onClickRef.current(end)
          }
        }
      }

      window.addEventListener('touchmove', onTouchMove)
      window.addEventListener('touchend', onTouchEnd)
    },
    [containerRef],
  )

  return {
    onMouseDown,
    onTouchStart,
  }
}
