export function isDefined<T>(t: T | undefined): t is T {
  return t !== undefined
}

export function isObjectEmpty(obj: object) {
  return Object.keys(obj).length === 0
}

export function isObject(input: unknown): input is Record<string, unknown> {
  return typeof input === 'object' && input !== null && !Array.isArray(input)
}
