import { Range } from 'common/types'
import { useEffect, useState } from 'react'
import { RangeControl } from './RangeControl'
import { useHorizontalDrag } from './hooks/useHorizontalDrag'

type Props = {
  trackRef: React.RefObject<HTMLDivElement>
  range: Range
  duration: number
  color: string
  text: string
  onChange?: (range: Range, startX: number, endX: number) => void
  onClick?: (range: Range) => void
}

export const RangeSlider = ({
  trackRef,
  range,
  duration,
  color,
  text,
  onChange,
  onClick,
}: Props) => {
  const [pendingRange, setPendingRange] = useState(range)

  useEffect(() => {
    setPendingRange(range)
  }, [range])

  const handleMouseUp = () =>
    onChange && onChange(range, pendingRange.start, pendingRange.end)

  const startMouseDragHandlers = useHorizontalDrag(trackRef, {
    onDrag: ({ end }) => {
      if (trackRef.current === null) return
      const trackRect = trackRef.current.getBoundingClientRect()
      setPendingRange((prevPendingRange) => ({
        ...prevPendingRange,
        start: Math.min(
          prevPendingRange.end,
          Math.max((duration * end) / trackRect.width, 0),
        ),
      }))
    },
    onEnd: handleMouseUp,
  })

  const endMouseDragHandlers = useHorizontalDrag(trackRef, {
    onDrag: ({ end }) => {
      if (trackRef.current === null) return
      const trackRect = trackRef.current.getBoundingClientRect()
      setPendingRange((prevPendingRange) => ({
        ...prevPendingRange,
        end: Math.max(
          prevPendingRange.start,
          Math.min((duration * end) / trackRect.width, duration),
        ),
      }))
    },
    onEnd: handleMouseUp,
  })

  const rangeDragHandlers = useHorizontalDrag(trackRef, {
    onClick: () => {
      if (onClick) onClick(range)
    },

    onDrag: ({ delta }) => {
      if (trackRef.current === null) return
      const trackRect = trackRef.current.getBoundingClientRect()
      const durationDelta = (duration * delta) / trackRect.width
      setPendingRange((prevPendingRange) => {
        const start = Math.max(prevPendingRange.start + durationDelta, 0)
        const end = Math.min(prevPendingRange.end + durationDelta, duration)
        return {
          ...prevPendingRange,
          start,
          end,
        }
      })
    },
    onEnd: handleMouseUp,
  })

  const isRangeValid = pendingRange.start !== pendingRange.end

  return (
    <div
      className={`absolute bottom-0 top-0 flex flex-row ${color} items-center justify-center bg-opacity-50`}
      style={{
        left: `${(pendingRange.start / duration) * 100}%`,
        right: `${100 - (pendingRange.end / duration) * 100}%`,
      }}
      {...rangeDragHandlers}
    >
      <RangeControl
        invalid={!isRangeValid}
        position="top"
        {...startMouseDragHandlers}
      ></RangeControl>
      <span className="truncate p-2 text-sm font-semibold capitalize">
        {text}
      </span>
      <RangeControl
        invalid={!isRangeValid}
        position="bottom"
        {...endMouseDragHandlers}
      ></RangeControl>
    </div>
  )
}
