import React, { useState } from 'react'
import { Deferred } from 'shared/utils/web/deferred'
import { Button } from '../components'
import { Dialog, DIALOG_CLOSED_REASON } from './Dialog'

export const LabelsProposalsDialog: React.FC<{
  labelsProposalsDialogData: {
    labelsProposals: string[]
    deferred: Deferred<string[]>
  }
}> = ({ labelsProposalsDialogData }) => {
  const { labelsProposals, deferred } = labelsProposalsDialogData
  const [text, setText] = useState(labelsProposals.join('\n'))

  const handleClose = () => {
    deferred.reject(DIALOG_CLOSED_REASON)
  }

  return (
    <Dialog
      title="Ajouter un commentaire facultatif / Suggérer un nouveau label"
      onClose={() => handleClose()}
    >
      <div className="flex flex-col gap-4 pt-4">
        <textarea
          className="p-2 text-black"
          onChange={(event) => setText(event.target.value)}
          value={text}
          rows={3}
        ></textarea>

        <Button
          primary={true}
          onClick={() => {
            deferred.resolve(
              text.split('\n').filter((proposal) => proposal !== ''),
            )
          }}
        >
          Valider
        </Button>
      </div>
    </Dialog>
  )
}
