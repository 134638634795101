import {
  LABEL_TEXT,
  LabelKey,
  getLabelsGroupConfigFromTitle,
} from 'common/ontology'
import { LabelsPagination } from 'common/types'
import { isObjectEmpty } from 'shared/utils/defined'

type Props = {
  activatedLabels: Partial<Record<LabelKey, LabelKey[]>>
  labelsPagination: LabelsPagination
}

export const Summary = ({ activatedLabels, labelsPagination }: Props) => {
  return !isObjectEmpty(activatedLabels) ? (
    <div className="flex flex-auto flex-col">
      <div className="flex justify-center p-4">
        <h3>Label(s) sélectionné(s)</h3>
      </div>
      <div className="flex grow flex-row flex-wrap items-center justify-evenly">
        {(Object.entries(activatedLabels) as [LabelKey, LabelKey[]][])
          .sort(([a], [b]) => a.localeCompare(b))
          .map(([labelsGroupTitle, subLabels]) => {
            const labelsGroup = getLabelsGroupConfigFromTitle(
              labelsPagination,
              labelsGroupTitle,
            )
            return (
              <div className="flex gap-2" key={labelsGroupTitle}>
                <div className="flex flex-col justify-center rounded bg-green-600 px-2 font-semibold">
                  {LABEL_TEXT[labelsGroupTitle]}
                </div>
                {labelsGroup.subLabels && (
                  <div className="flex flex-row gap-2 rounded border-2 p-2">
                    {subLabels.map((subLabel) => (
                      <div
                        className="flex flex-col justify-center rounded bg-green-600 px-2 font-semibold"
                        key={`${labelsGroupTitle}.${subLabel}`}
                      >
                        {`${LABEL_TEXT[subLabel]}`}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )
          })}
      </div>
    </div>
  ) : (
    <div className="flex flex-auto items-center justify-center">
      <h3>Aucun label sélectionné</h3>
    </div>
  )
}
